<template>
	<div>
		<transition name="slide-fade">
			<div
				v-if="isIndexing"
				class="d-flex align-items-center mt-2"
				v-b-modal.indexer-modal
			>
				<font-awesome-icon
					class="mr-2"
					:icon="['fas', 'atom-alt']"
					spin
				/>
				{{ $t('storage.indexation.indexing') }}
			</div>
		</transition>

		<b-modal
			id="indexer-modal"
			:title="$t('storage.indexation.title')"
			@shown="onShow"
			@hidden="onHide"
			hide-footer
		>
			<div v-if="indexInfos.current < indexInfos.max && indexInfos.current >= 0">
				<b-progress
					:value="indexInfos.current"
					:max="indexInfos.max"
					show-progress
					height="2rem"
				>
				</b-progress>
				<h5 class="text-center mt-2">
					{{ $t('storage.indexation.count', indexInfos) }}
				</h5>
			</div>
			<h5 v-else class="text-center mt-2">
				{{ $t('storage.indexation.finished') }}
			</h5>
		</b-modal>
	</div>
</template>

<script>
import NoSleep from 'nosleep.js'
import Navigation from '@/mixins/Navigation'

export default {
    name: 'Indexer',
    mixins: [Navigation],
	data() {
		return {
			isIndexing: false,
			timerId: null,
			noSleep: null,
			indexInfos: {
				current: -1,
				max: 0
			},
			events_tab: {
				'Indexation::stop': () => {
					this.isIndexing = false
					this.disableNoSleep()
				},
				'Indexation::start': () => {
					this.isIndexing = true
					this.enableNoSleep()
				}
			}
		}
	},
	methods: {
		async updateIndexingCount() {
			const itemsLeft = await this.$sync.getIndexingCount()

			if(itemsLeft > this.indexInfos.max) {
				this.indexInfos.max = itemsLeft
			}

			this.indexInfos.current = this.indexInfos.max - itemsLeft
		},
		enableNoSleep() {
			if(!this.noSleep && document.hasFocus()) {
				//this.noSleep = new NoSleep()
				//this.noSleep.enable()
			}
		},
		disableNoSleep() {
			if(this.noSleep) {
				//this.noSleep.disable()
				//this.noSleep = null
			}
		},
		onShow() {
			this.updateIndexingCount()
			this.timerId = setInterval(this.updateIndexingCount, 1000)
		},
		onHide() {
			clearInterval(this.timerId)
		}
	}
}
</script>

<style>
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
